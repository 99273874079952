// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { RedirectSourceEnum } from '@reconcept-platform/shared/authentication';

export const environment = {
  production: false,
  environment: 'staging',

  // the Application id for this specific app
  authAppId: 'XvK4CxQvtuGKXWdlsDbbNSOkv_WwUpxZIgTrhvheuuo',

  // the successful login return url
  authRedirectURI: 'https://workspacestaging.reconcept.nl/login/success/',

  // the RedirectSource corresponding with backend
  authRedirectSource: RedirectSourceEnum.workspaceDashboard,

  // the requested grant scope
  authScope: 'user workspace',
  usesAnalytics: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
