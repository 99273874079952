import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment as platformEnvironment } from '@reconcept-platform/env';
import { ErrorSinkService, FeatureFlagDirective, provideFeatureFlag } from '@reconcept-platform/shared/app/core';
import {
  AUTH_TOKEN_SERVICE,
  provideAuthTokens,
  RedirectFromParamsGuard,
  RedirectToLoginGuard,
  SuccessfulRedirectGuard,
  WebAuthTokenService,
  WebSessionGuard,
} from '@reconcept-platform/shared/authentication';
import {
  ApolloGraphqlService,
  initializeGraphQLService,
  introspectionQueryResultData,
  sanitizedKeys,
  SharedDataAccessModule,
  VIEWER_SERVICE,
  WorkspaceTypePolicies,
} from '@reconcept-platform/shared/data-access';
import { SharedEmailFeedbackModule } from '@reconcept-platform/shared/email-feedback';
import { SharedNavigationNavModule } from '@reconcept-platform/shared/navigation/nav';
import { SharedNavigationNavContentModule } from '@reconcept-platform/shared/navigation/nav-content';
import { SharedUiAppLoadingModule } from '@reconcept-platform/shared/ui/core/app-loading';
import { SharedUiFilePreviewModule } from '@reconcept-platform/shared/ui/core/file-preview';
import { SharedUiModalModule } from '@reconcept-platform/shared/ui/core/modal';
import { SharedUiToastNotificationsModule } from '@reconcept-platform/shared/ui/core/toast-notifications';
import { RcpMissingTranslationHanderService, USES_ANALYTICS } from '@reconcept-platform/shared/util';
import { WorkspaceAppAnalyticsModule } from '@reconcept-platform/workspace/app/analytics';
import {
  WorkspaceViewerOnboardedRedirectGuard,
  WorkspaceViewerService,
} from '@reconcept-platform/workspace/data-access';
import { ApolloModule } from 'apollo-angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found.component';
import Bugsnag from '@bugsnag/js';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ACCESSIBLE_COLLECTION_SHORTNAMES } from '@reconcept-platform/guidelines/data-access';

registerLocaleData(localeNl);

// configure Bugsnag
// cannot use isDevMode here, will be called before production mode is set
if (environment.production) {
  Bugsnag.start({
    apiKey: '98cb4899b91567c800fc74167974dffa',
    releaseStage: environment.environment,
    appVersion: platformEnvironment.version,
    redactedKeys: sanitizedKeys,
  });
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    FeatureFlagDirective,
    BrowserModule,
    BrowserAnimationsModule,
    ApolloModule,
    SharedDataAccessModule,
    SharedNavigationNavContentModule,
    SharedNavigationNavModule,
    SharedUiAppLoadingModule,
    SharedUiModalModule,
    SharedUiFilePreviewModule,
    WorkspaceAppAnalyticsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: RcpMissingTranslationHanderService },
    }),
    RouterModule.forRoot(
      [
        // successful return after authorization
        {
          path: 'login',
          children: [
            {
              // this path should match the redirect uri in environment and server side Application
              path: 'success',
              canActivate: [SuccessfulRedirectGuard],
              data: {
                title: 'Succesvolle doorverwijzing',
                internalRedirectPath: ['dashboard'],
              },
              children: [],
            },

            // Any other login path, redirect to auth app (backward compatibility)
            {
              path: '**',
              pathMatch: 'full',
              canActivate: [RedirectToLoginGuard],
              children: [], //required
            },
          ],
        },

        {
          path: 'password-reset/:token',
          pathMatch: 'full',
          canActivate: [RedirectToLoginGuard],
          children: [], //required
        },

        // The welcome path should redirect to the login app (backward compatibility)
        {
          path: 'welcome/:token',
          pathMatch: 'full',
          canActivate: [RedirectToLoginGuard],
          children: [], //required
        },

        {
          path: 'onboarding',
          loadChildren: () =>
            import('@reconcept-platform/workspace/account-onboarding/web').then(
              (module) => module.WorkspaceAccountOnboardingWebModule,
            ),
        },

        {
          path: '',
          canActivate: [RedirectFromParamsGuard, WebSessionGuard, WorkspaceViewerOnboardedRedirectGuard],
          loadChildren: () =>
            import('@reconcept-platform/workspace/app/web').then((module) => module.WorkspaceAppWebModule),
        },

        { path: '**', component: NotFoundComponent },
      ],
      { initialNavigation: 'enabledBlocking', paramsInheritanceStrategy: 'always', bindToComponentInputs: true },
    ),
    SharedUiToastNotificationsModule,
    SharedEmailFeedbackModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    provideFeatureFlag(),
    { provide: ErrorHandler, useClass: ErrorSinkService },
    { provide: LOCALE_ID, useValue: 'nl-NL' },

    { provide: AUTH_TOKEN_SERVICE, useClass: WebAuthTokenService },
    provideAuthTokens(
      environment.authRedirectURI,
      environment.authAppId,
      environment.authRedirectSource,
      environment.authScope,
    ),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeGraphQLService(
        'APOLLO_PORTFOLIO',
        platformEnvironment.workspaceApiURL,
        introspectionQueryResultData,
        WorkspaceTypePolicies,
      ),
      deps: [ApolloGraphqlService],
    },
    {
      provide: USES_ANALYTICS,
      useValue: environment.usesAnalytics,
    },
    {
      provide: VIEWER_SERVICE,
      useClass: WorkspaceViewerService,
    },
    {
      provide: ACCESSIBLE_COLLECTION_SHORTNAMES,
      useValue: ['*', '!demo*', '!reconcept*'],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
