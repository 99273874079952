import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { SendMailApi } from '@reconcept-platform/portfolio/data-access';
import { MixpanelService, User, VIEWER_SERVICE, ViewerService } from '@reconcept-platform/shared/data-access';
import { switchMap, take } from 'rxjs';

interface FeelingResponse {
  icon: IconName;
  score: number;
}

@Component({
  selector: 'rcp-feedback-window',
  templateUrl: 'feedback-window.component.html',
  styleUrls: ['./feedback-window.component.scss'],
})
export class FeedbackWindowComponent {
  @Output() closeWindow = new EventEmitter<void>();

  feelings: FeelingResponse[] = [
    {
      icon: 'face-angry',
      score: 1,
    },
    {
      icon: 'face-disappointed',
      score: 2,
    },
    {
      icon: 'face-meh',
      score: 3,
    },
    {
      icon: 'face-laugh',
      score: 4,
    },
    {
      icon: 'face-grin-hearts',
      score: 5,
    },
  ];
  currentStep = 1;

  feedbackComments: string;

  private selectedFeeling: FeelingResponse;

  constructor(
    private sendEmail: SendMailApi,
    @Inject(VIEWER_SERVICE) private viewer: ViewerService,
    private mixpanelService: MixpanelService,
  ) {}

  select(feeling: FeelingResponse) {
    this.selectedFeeling = feeling;
    this.currentStep++;
  }

  close() {
    this.closeWindow.emit();
  }

  sendFeedback() {
    this.viewer
      .getViewer()
      .pipe(
        take(1),
        switchMap((viewer: User) => {
          const body = this.createEmailBody(viewer, this.selectedFeeling, this.feedbackComments);
          const subject = 'Feedback uit ' + window.location.hostname;

          this.mixpanelService.trackEvent('Application Feedback', {
            score: this.selectedFeeling.score,
          });

          return this.sendEmail.sendMailToSupport(subject, body);
        }),
      )
      .subscribe();

    this.currentStep++;
  }

  trackSupportCentre() {
    this.mixpanelService.trackEvent('Open SupportCentrum');
  }

  private createEmailBody(viewer: User, selectedFeeling: FeelingResponse, feedbackComments: string) {
    // <!-- DISABLE_TRANSLATION_CHECK --> because this a mail to our support, and does not need translation
    return ` 
      <table>
      <tr>
        <td>User ID</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td>${viewer.id}</td>
      </tr>
      
      <tr>
        <td>User Email</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td>${viewer.email}</td>
      </tr>
      
      <tr>
        <td>URL</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td>${window.location.href}</td>
      </tr>
      
      <tr>
        <td>Browser</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td>${this.browserDetect()}</td>
      </tr>
      
      <tr>
        <td>Score</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td>${selectedFeeling.score}</td>
      </tr>
      
      <tr>
        <td>Opmerkingen</td><!-- DISABLE_TRANSLATION_CHECK -->
        <td style="white-space: pre-line">${feedbackComments}</td>
      </tr>
      </table>
    `;
  }

  private browserDetect() {
    const userAgent = window.navigator.userAgent;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      return 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      return 'firefox';
    } else if (userAgent.match(/safari/i)) {
      return 'safari';
    } else if (userAgent.match(/opr\//i)) {
      return 'opera';
    } else if (userAgent.match(/edg/i)) {
      return 'edge';
    } else {
      return 'Onbekende browser'; // DISABLE_TRANSLATION_CHECK
    }
  }
}
