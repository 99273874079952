<main>
  <!-- Navs -->
  <rcp-top-nav [viewerIsOnboarded]="(viewer$ | async)?.isOnboarded"></rcp-top-nav>

  <div class="side-nav-container">
    <rcp-side-nav></rcp-side-nav>

    <!-- Content -->
    <div class="main-outlet-container">
      <rcp-app-loading></rcp-app-loading>
      <router-outlet></router-outlet>
    </div>
  </div>
</main>

<rcp-modal-container></rcp-modal-container>
<rcp-toast-notifications></rcp-toast-notifications>
<rcp-file-preview></rcp-file-preview>
<rcp-feedback-icon *featureFlag="'feedbackIconFeatureFlag'" rcpFeedbackLocation class="no-print"></rcp-feedback-icon>
