import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackIconComponent } from './feedback-icon/feedback-icon.component';
import { FeedbackWindowComponent } from './feedback-window/feedback-window.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faFaceAngry,
  faFaceDisappointed,
  faFaceGrinHearts,
  faFaceLaugh,
  faFaceMeh,
  faFaceParty,
  faMessageSmile,
} from '@fortawesome/pro-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FeedbackLocationDirective } from './feedback-location.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, FormsModule, TranslateModule],
  declarations: [FeedbackIconComponent, FeedbackWindowComponent, FeedbackLocationDirective],
  exports: [FeedbackIconComponent, FeedbackLocationDirective],
})
export class SharedEmailFeedbackModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faMessageSmile,
      faFaceAngry,
      faFaceDisappointed,
      faFaceMeh,
      faFaceLaugh,
      faFaceGrinHearts,
      faFaceParty,
    );

    library.addIcons(faChevronDown, faChevronUp, faChevronRight, faChevronLeft);
  }
}
