import { Component } from '@angular/core';

@Component({
  selector: 'rcw-not-found',
  template: `
    <!--   show the reconcept logo + topbar   -->
    <rcp-nav-content [onlyTop]="true"></rcp-nav-content>
    <div class="view-container">
      <div class="view-main">
        <section class="fold">
          <h1>We konden niet vinden wat je zocht</h1>

          <p>
            Je kunt ons helpen door ons te vertellen wat je zocht en waar je vandaan kwam, via de blauwe knop
            rechtsonderin
          </p>
          <p><a routerLink="/dashboard">Klik hier om naar het dashboard te gaan</a></p>
        </section>
      </div>
    </div>
  `,
  styles: [
    `
      h1 {
        font-size: 3rem;
      }

      p {
        font-size: 1.2rem;
      }

      * {
        text-align: center;
      }

      .view-main {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .fold {
        margin-top: -25rem;
      }
    `,
  ],
})
export class NotFoundComponent {}
