import { Component, OnInit } from '@angular/core';
import { UserView, WorkspaceViewerService } from '@reconcept-platform/workspace/data-access';
import { Observable } from 'rxjs';
import { WebModalListenerService } from '@reconcept-platform/shared/ui/core/modal';

@Component({
  selector: 'rcw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  viewer$: Observable<UserView>;

  constructor(
    private viewerService: WorkspaceViewerService,
    private modalListenerService: WebModalListenerService,
  ) {}

  ngOnInit() {
    this.modalListenerService.initQueryParamsListener();
    this.viewer$ = this.viewerService.watchViewer();
  }
}
