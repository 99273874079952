<section class="no-border" [attr.class]="'step-' + currentStep">
  <fa-icon [icon]="['fal', 'times']" class="window-close clickable" (click)="close()"></fa-icon>

  <ng-container *ngIf="currentStep === 1">
    <header class="flex justify-center">
      <span class="text blue">{{ 'qz-what-do-you-think-this-page-zm' | translate }}</span>
    </header>
    <div class="responses flex justify-evenly">
      <div
        class="feeling-container clickable yellow text selecting"
        *ngFor="let feeling of feelings"
        (click)="select(feeling)"
      >
        <fa-icon [icon]="['fas', feeling.icon]" size="2x"></fa-icon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="currentStep === 2">
    <header class="flex justify-center">
      <span class="text blue">{{ 'qz-how-can-we-improve-zm' | translate }}</span>
    </header>

    <div class="feedback flex">
      <textarea [(ngModel)]="feedbackComments"></textarea>
      <button class="button positive pull-right" (click)="sendFeedback()">
        {{ 'qz-send-feedback-zm' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="currentStep === 3">
    <header class="flex justify-center">
      <span class="text blue">{{ 'qz-thank-you-for-your-feedback-zm' | translate }}</span>
    </header>
    <div class="margin-bottom left">{{ 'qz-thanks-to-your-feedback-we-make-reconcept-zm' | translate }}</div>

    <div class="center margin-bottom">
      <fa-icon [icon]="['fas', 'face-party']" class="text positive" size="2x"></fa-icon>
    </div>
  </ng-container>

  <div class="text smaller center help-link">
    {{ 'qz-need-help-have-look-at-our-zm' | translate }}
    <a href="https://reconcept.nl/support" target="_blank" (click)="trackSupportCentre()">{{
      'qz-support-center-zm' | translate
    }}</a
    >!
  </div>
</section>
