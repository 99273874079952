import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { FEEDBACK_CUSTOM_SETTINGS_KEY, FEEDBACK_DEFAULT_LOCATION } from './feedback.utils';
import { NavigationService } from '@reconcept-platform/shared/navigation/nav-content';
import { VIEWER_SERVICE, ViewerService } from '@reconcept-platform/shared/data-access';
import { combineLatest } from 'rxjs';

export interface FeedbackLocation {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

@Directive({
  selector: '[rcpFeedbackLocation]',
})
export class FeedbackLocationDirective implements OnInit {
  constructor(
    private el: ElementRef,
    @Inject(VIEWER_SERVICE) private viewerService: ViewerService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    combineLatest([this.viewerService.watchViewerCustomSettings(), this.navigationService.sidebarCollapse$]).subscribe({
      next: ([viewer, sidebarCollapsed]) => {
        const feedbackLocation = viewer?.customSettings
          ? viewer.customSettings[FEEDBACK_CUSTOM_SETTINGS_KEY] ?? FEEDBACK_DEFAULT_LOCATION
          : FEEDBACK_DEFAULT_LOCATION;

        this.el.nativeElement.style.cssText = this.getPositionCss(feedbackLocation, sidebarCollapsed);
      },
    });
  }

  private getPositionCss(feedbackLocation: FeedbackLocation, sidebarCollapsed: boolean) {
    return `
      position: absolute;
      ${this.getLocationCss('left', feedbackLocation, sidebarCollapsed ? 0 : 250)}
      ${this.getLocationCss('right', feedbackLocation)}
      ${this.getLocationCss('top', feedbackLocation)}
      ${this.getLocationCss('bottom', feedbackLocation)}
    `;
  }

  private getLocationCss(key: keyof FeedbackLocation, feedbackLocation: FeedbackLocation, offset = 0) {
    return feedbackLocation[key] ? key + ': ' + (feedbackLocation[key] + offset) + 'px;' : '';
  }
}
