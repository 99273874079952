import { Component, OnInit } from '@angular/core';
import { PortfolioViewerService } from '@reconcept-platform/portfolio/data-access';
import { FEEDBACK_CUSTOM_SETTINGS_KEY, FEEDBACK_DEFAULT_LOCATION } from '../feedback.utils';
import { FeedbackLocation } from '../feedback-location.directive';

@Component({
  selector: 'rcp-feedback-icon',
  template: `
    <div
      class="feedback-window"
      [class.feedback-up]="currentLocation.top"
      [class.feedback-right]="currentLocation.right"
      [class.feedback-down]="currentLocation.bottom"
      [class.feedback-left]="currentLocation.left"
    >
      <rcp-feedback-window *ngIf="showWindow" (closeWindow)="toggleFeedbackWindow()"></rcp-feedback-window>
    </div>

    <div
      class="icon-container"
      [class.hover]="showLocationChevrons"
      (mouseenter)="toggleLocationChevrons()"
      (mouseleave)="toggleLocationChevrons()"
    >
      <fa-icon
        [icon]="['fal', 'chevron-up']"
        size="lg"
        class="rotate-icon up clickable"
        [class.hide]="currentLocation.top"
        (click)="moveIcon('up')"
      ></fa-icon>
      <fa-icon
        [icon]="['fal', 'chevron-left']"
        size="lg"
        class="rotate-icon left clickable"
        [class.hide]="currentLocation.left"
        (click)="moveIcon('left')"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'message-smile']"
        class="text blue clickable feedback"
        (click)="toggleFeedbackWindow()"
      ></fa-icon>
      <fa-icon
        [icon]="['fal', 'chevron-right']"
        size="lg"
        class="rotate-icon right clickable"
        [class.hide]="currentLocation.right"
        (click)="moveIcon('right')"
      ></fa-icon>
      <fa-icon
        [icon]="['fal', 'chevron-down']"
        size="lg"
        class="rotate-icon down clickable"
        [class.hide]="currentLocation.bottom"
        (click)="moveIcon('down')"
      ></fa-icon>
    </div>
  `,
  styleUrls: ['./feedback-icon.component.scss'],
})
export class FeedbackIconComponent implements OnInit {
  showWindow: boolean;
  showLocationChevrons: boolean;
  currentLocation: FeedbackLocation;

  private timer: ReturnType<typeof setTimeout> | null;

  constructor(private viewerService: PortfolioViewerService) {}

  ngOnInit(): void {
    this.currentLocation =
      this.viewerService.getViewerCustomSetting(FEEDBACK_CUSTOM_SETTINGS_KEY) ?? FEEDBACK_DEFAULT_LOCATION;
  }

  toggleFeedbackWindow() {
    this.showWindow = !this.showWindow;
  }

  toggleLocationChevrons() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.showLocationChevrons = false;
    } else {
      this.timer = setTimeout(() => {
        this.showLocationChevrons = true;
      }, 800);
    }
  }

  moveIcon(newLocation: 'up' | 'right' | 'down' | 'left') {
    const newLocationSetting = this.getNewLocation(this.currentLocation, newLocation);
    this.viewerService.setViewerCustomSetting(FEEDBACK_CUSTOM_SETTINGS_KEY, newLocationSetting).subscribe({
      next: () => {
        this.currentLocation = newLocationSetting;
      },
    });
  }

  private getNewLocation(
    currentLocation: FeedbackLocation,
    newLocation: 'up' | 'right' | 'down' | 'left',
  ): FeedbackLocation {
    switch (newLocation) {
      case 'up':
        return {
          ...currentLocation,
          top: 60,
          bottom: 0,
        };

      case 'right':
        return {
          ...currentLocation,
          right: 5,
          left: 0,
        };

      case 'down':
        return {
          ...currentLocation,
          bottom: 10,
          top: 0,
        };

      case 'left':
        return {
          ...currentLocation,
          left: 60,
          right: 0,
        };
    }

    return currentLocation;
  }
}
